:root{
  --bg-light-900: #151515;
  --bg-light-100: #222222;
  --bg-light-50: #393939;
  --text-black-900: #FFFFFF;
  --text-black-700: #E9E9E9;
  --skin-color: red;
}

*{
  scroll-behavior: smooth;
}

.dark-shadow{
  box-shadow: 0 0 20px rgba(48, 46, 77, .15);
}

.p-15{
  padding: 0 15px;
}

.btn{
  font-size: 16px;
  font-weight: 500;
  padding: 12px 35px;
  background: var(--skin-color) ;
  border-radius: 40px;
  display: inline-block;
  white-space: nowrap;
  color: var(--bg-light-900);
  transition: all .2s ease;
}

.btn:hover{
  transform: scale(1.01);
}

.container{
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.row{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  position: relative;
}

section{
  padding: 0 30px;
  background-color: var(--bg-light-900);
  min-height: 100vh;
  display: block;
  opacity: 1;
}

.section .container{
  padding-top: 60px;
  padding-bottom: 70px;
}

.section-title{
  flex: 0 0 100%;
  width: 100%;
  margin-bottom: 60px;
}

.section-title h2{
  font-size: 40px;
  color: var(--text-black-900);
  font-weight: 700;
  position: relative;
}

.section-title h2::before{
  content: '';
  position: absolute;
  width: 40px;
  height: 4px;
  background-color: var(--skin-color);
  top: 100%;
  left: 0;
}

.section-title h2::after{
  content: '';
  position: absolute;
  width: 25px;
  height: 4px;
  background-color: var(--skin-color);
  top: 110%;
  left: 0;
  margin-top: 0px;
}

.main-content{
  padding-left: 270px;
}

/* ---------------------------ASIDE--------------------------- */

.aside{
  width: 270px;
  height: 100%;
  border-right: 1px solid var(--bg-light-50) ;
  position: fixed;
  background-color: var(--bg-light-100);
  top: 0;
  left: 0;
  z-index: 10;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aside .logo{
  position: absolute; /*  pour le faire sortir de l'heritage de .aside */
  top: 50px;
  font-size: 40px;
  text-transform: capitalize;
}

.aside .logo a{
  color: var(--text-black-900);
  font-weight: 700;
  padding: 15px 20px;
  font-size: 30px;
  letter-spacing: 4px;
  position: relative;
}

.aside .logo a::after{
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-bottom: 5px solid var(--skin-color);
  border-left: 5px solid var(--skin-color);
  bottom: 0;
  left: 0;
}

.aside .logo a::before{
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-top: 5px solid var(--skin-color);
  border-right: 5px solid var(--skin-color);
  top: 0;
  right: 0;
}

.aside .logo a span{
  font-family: 'Clicker Script', cursive;
  font-size: 40px;
}

.aside .toggle{
  height: 40px;
  width: 45px;
  cursor: pointer;
  position: fixed;
  left: 300px;
  top: 20px;
  border: 1px solid var(--bg-light-50);
  border-radius: 5px;
  background: var(--bg-light-100);
  display: none;
  justify-content: center;
  align-items: center;
}

.aside .toggle span{
  position: relative;
  display: inline-block;
  height: 2px;
  width: 18px;
  background: var(--skin-color);
}

.aside .nav{
  margin-top: 70px;
}

.aside .nav li{
  margin-bottom: 25px;
  display: block;
}

.aside .nav li a{
  font-size: 16px;
  font-weight: 600;
  padding: 5px 15px;
  color: var(--text-black-900);
  border-bottom: 1px solid var(--bg-light-50);
}

.aside .nav li a.active{
  color: var(--skin-color);
}

.aside .nav li a i{
  margin-right: 15px;
}

/* ---------------------------Home--------------------------- */

.home{
  min-height: 100vh;
  display: flex;
  color: var(--text-black-900);
}

.home .home-infos{
  flex: 0 0 60%;
  max-width: 60%;
}

.home .home-infos p{
  font-size: 20px;
  margin-bottom: 70px;
  color: var(--text-black-700);
}

.hello{
  font-size: 28px;
  margin: 15px 0;
}

.hello span{
  font-size: 30px;
  font-family: 'Clicker Script';
  color: var(--skin-color);
  font-weight: 700;
}

.my-profession{
  font-size: 30px;
  margin: 15px 0;
}

.typing{
  color: var(--skin-color);
}

.home .home-img{
  flex: 0 0 40%;
  max-width: 40%;
  text-align: center;
  position: relative;
}

.home .home-img::after{
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  border-bottom: 10px solid var(--skin-color);
  border-right: 10px solid var(--skin-color);
  right: 20px;
  bottom: -30px;
}

.home .home-img::before{
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  border-top: 10px solid var(--skin-color);
  border-left: 10px solid var(--skin-color);
  left: 20px;
  top: -30px;
}

.home .home-img img{
  height: 400px;
  width: 280px;
  margin: auto;
  object-fit: cover;
  border-radius: 5px;
}

/*----------------------About------------------------*/

.about .about-content .about-text, .about .about-content{
  flex: 0 0 100%;
  max-width: 100%;
}

.about .about-content .about-text h3{
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 700;
  color: var(--text-black-900);
}

.about .about-content .about-text h3 span{
  color: var(--skin-color);
}

.about .about-content .about-text p{
  line-height: 25px;
  font-size: 16px;
  color: var(--text-black-700);
}

.about .about-content .personal-info{
  flex: 0 0 60%;
  max-width: 60%;
  margin-top: 40px;
}

.about .about-content .personal-info .info-item{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}

.about .about-content .personal-info .info-item p{
  font-weight: 600;
  padding: 10px 0;
  font-size: 16px;
  color: var(--text-black-900);
  border-bottom: 1px solid var(--bg-light-50);
}

.about .about-content .personal-info .info-item p span{
  font-weight: 400;
  color: var(--text-black-700);
  margin-left: 5px;
  display: inline-block;
}

.about .about-content .personal-info .buttons{
  margin-top: 30px;
}

.about .about-content .personal-info .buttons .btn-2{
  margin-right: 15px;
  margin-top: 10px;
  background-color: var(--bg-light-100);
  color: var(--text-black-900);
  border: 1px solid var(--skin-color);
}

.about .about-content .personal-info .buttons .btn{
  margin-right: 15px;
  margin-top: 10px;
}

.about .about-content .skills{
  flex: 0 0 40%;
  max-width: 40%;
  margin-top: 40px;
}

.about .about-content .skills .skill-item{
  flex: 0 0 100%;
  width: 100%;
  margin-bottom: 25px;
}

.about .about-content .skills .skill-item h5{
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--text-black-900);
}

.about .about-content .skills .skill-item .progress{
  background-color: var(--bg-light-50);
  height: 7px;
  border-radius: 5px;
  width: 100%;
  position: relative;
}

.about .about-content .skills .skill-item .skill-percent{
  position: absolute;
  right: 0;
  color: var(--text-black-900);
  top: -40px;
  line-height: 40px;
}

.about .about-content .education,
.about .about-content .experience{
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 30px;
}

.about .about-content .education h3.title,
.about .about-content .experience h3.title {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 700;
  color: var(--text-black-900);
}

.about .about-content .timeline-box{
  flex: 0 0 100%;
  max-width: 100%;
}

.about .about-content .timeline-box .timeline{
  background-color: var(--bg-light-100);
  padding: 30px 15px;
  border: 1px solid var(--bg-light-50);
  border-radius: 10px;
  position: relative;
}

.about .about-content .timeline-box .timeline .timeline-item{
  position: relative;
  padding-left: 37px;
  padding-bottom: 50px;
}

.about .about-content .timeline-box .timeline .timeline-item:last-child{
  padding-bottom: 0;
}

.about .about-content .timeline-box .timeline .timeline-item::before{
  content: '';
  width: 0.1px;
  height: 100%;
  position: absolute;
  left: 7px;
  top: 0;
  background-color: var(--skin-color);
}

.about .about-content .timeline-box .timeline .circle-dot{
  position: absolute;
  left: 0;
  top: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: var(--skin-color);
}

.about .about-content .timeline-box .timeline .timeline-date{
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;color: var(--text-black-700);
}

.about .about-content .timeline-box .timeline .timeline-date .fa{
  margin-right: 5px;
}

.about .about-content .timeline-box .timeline .timeline-title{
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: capitalize;
  color: var(--text-black-900);
}

.about .about-content .timeline-box .timeline .timeline-text{
  line-height: 20px;
  font-size: 15px;
  text-align: left;
  color: var(--text-black-700);
}

/*----------------------Services------------------------*/

.service .container{
  padding-bottom: 40px;
}

.service .service-item{
  margin-bottom: 30px;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  cursor: pointer;
}

.service .service-item .service-item-inner{
  background-color: var(--bg-light-100);
  border: 1px solid var(--bg-light-50);
  border-radius: 10px;
  padding: 30px 15px;
  text-align: center;
  transition: all .3s ease;
}

.service .service-item .service-item-inner:hover{
  box-shadow: 0 0 20px rgba(48, 46, 77, .15);
}

.service .service-item .service-item-inner .icon{
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 30px;
  text-align: center;
  transition: all .5s ease;
}

.service .service-item .service-item-inner .icon .fa{
  font-size: 40px;
  line-height: 60px;
  color: var(--skin-color);
}

.service .service-item .service-item-inner:hover .icon{
  background-color: var(--skin-color);
}

.service .service-item .service-item-inner:hover .icon .fa{
  font-size: 25px;
  color: #ffffff;
}

.service .service-item .service-item-inner h4{
  font-size: 18px;
  margin-bottom: 15px;
  color: var(--text-black-900);
  font-weight: 700;
  text-transform: capitalize;
}

.service .service-item .service-item-inner p{
  font-size: 16px;
  color: var(--text-black-700);
  line-height: 25px;
}

/*----------------------Services------------------------*/

/*----------------------Portfolio------------------------*/


.portfolio .container{
  padding-bottom: 40px;
}

.portfolio .portfolio-heading{
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 40px;
}

.portfolio .portfolio-heading h2{
  color: var(--text-black-900);
  font-weight: 500;
}

.portfolio .portfolio-item{
  margin-bottom: 30px;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.portfolio .portfolio-item-inner{
  border: 6px solid var(--bg-light-100);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.portfolio .portfolio-item-inner .portfolio-img img{
  max-width: 100%;
  display: block;
}

/*----------------------Portfolio------------------------*/

/*----------------------Contact------------------------*/

.contact-title{
  color: var(--skin-color);
  text-align: center;
  font-size: 25px;
  margin-bottom: 20px;
}

.contact-sub-title{
  color: var(--text-black-900);
  text-align: center;
  font-size: 15px;
  margin-bottom: 60px;
}

.contact .contact-info-item{
  flex: 0 0 25%;
  max-width: 25%;
  text-align: center;
  margin-bottom: 60px;
}

.contact .contact-info-item .icon{
  display: block;
}

.contact .contact-info-item .icon .fa{
  font-size: 25px;
  color: var(--skin-color);
}

.contact .contact-info-item h4{
  font-size: 18px;
  font-weight: 700;
  color: var(--text-black-900);
  text-transform: capitalize;
  margin: 15px 0 5px;
}

.contact .contact-info-item p{
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  color: var(--text-black-700);
}

.contact .contact-form{
  flex: 0 0 100%;
  max-width: 100%;
}

.contact .contact-form .col-6{
  flex: 0 0 50%;
  max-width: 50%;
}

.contact .contact-form .col-12{
  flex: 0 0 100%;
  max-width: 100%;
}

.contact .contact-form .form-item{
  margin-bottom: 30px;
}

.contact .contact-form .form-item .form-control{
  width: 100%;
  height: 50%;
  border-radius: 30px;
  background-color: var(--bg-light-100);
  border: 1px solid var(--bg-light-50);
  padding: 10px 25px;
  font-size: 16px;
  color: var(--text-black-700);
  transition: all .3s ease;
}

.contact .contact-form .form-item .form-control:focus{
  box-shadow: 0 0 10px rgba(48, 46, 77, .15);
}

.contact .contact-form .form-item textarea.form-control{
  resize: none;
  height: 140px;
}

.contact .contact-form .btn{
  height: 50px;
  padding: 0 50px;
  border: none;
}

/*----------------------Responsive------------------------*/

@media (max-width: 1200px) {
  .aside{
    left: -270px;
  }

  .main-content{
    padding-left: 0;
  }

  .about .about-content .personal-info .info-item p span{
    display: block;
    margin-left: 0;
  }
}

@media (max-width: 990px) {
  .home .home-infos, .about .about-content .education, .about .about-content .experience{
    flex: 0 0 100%;
    max-width: 100%;
  }

  .home .home-img{
    display: none;
  }

  .portfolio .portfolio-item, .contact .contact-info-item, .service .service-item{
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 770px) {
  .about .about-content .skills, .about .about-content .personal-info{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .portfolio .portfolio-item, .contact .contact-info-item, .service .service-item{
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*----------------------Responsive------------------------*/

