@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&family=Poppins:wght@200;300;400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  text-decoration: none;
}
body{
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-size: 16px;
  font-family: 'Poppins' sans-serif;
}
::after, ::before{
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Poignée de la scrollbar */
::-webkit-scrollbar-thumb {
  background-color: red;
}







